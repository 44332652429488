import React from 'react';
import { observer } from 'mobx-react';
import { Animated } from 'react-native';
import { fontMaker } from '../helpers/fontMaker';
import { fonts } from '../styles/fonts';
import { colors } from '../styles/colors';
export const AppText = observer(({ style, light, bold, italic, children, ...props }) => {
    style = Array.isArray(style) ? style : [style];
    return (React.createElement(Animated.Text, Object.assign({ style: [
            { color: light ? '#fff' : colors.boxTextColor, fontSize: fonts.fontMedium },
            fontMaker({
                weight: bold ? 'Bold' : undefined,
                style: italic ? 'italic' : undefined
            }),
            ...style
        ] }, props), children));
});
