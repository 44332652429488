import React from "react";
import { StyleSheet, View, SafeAreaView, ScrollView, useWindowDimensions, Platform } from "react-native";
import { observer } from "mobx-react";
import { Switch, Route } from "../../routing";
import { colors } from "../../styles/colors";
import { pixelPerfect } from "../../helpers/pixelPerfect";
import Svg, { Path } from "react-native-svg";
import { LogoVertical } from "../../assets/LogoVertical";
import { LoginForm } from "./LoginForm";
import { Signup } from "./Signup";
import { Username } from "./Username";
import { SignupAnon } from "./SignupAnon";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { utils } from "../../firebaseSetup";
import { loginStyles } from "./login.styles";
import { Link } from "../../routing";
import { AppText } from "../../components/AppText";
import { Info } from "../Info/Info";
import { Challenge } from "../Challenge/Challenge";
const thingyAspectRatio = 528 / 750;
export const Login = observer(() => {
    const { width } = useWindowDimensions();
    const logoHeight = Math.min(thingyAspectRatio * width - 100, pixelPerfect(380));
    const logoAspectRatio = 380 / 346;
    return (React.createElement(SafeAreaView, { style: { flex: 1 } },
        React.createElement(ScrollView, { style: styles.container },
            React.createElement(View, { style: [
                    styles.logo,
                    {
                        width: width,
                        height: thingyAspectRatio * width,
                    },
                ] },
                React.createElement(Svg, { width: "100%", height: "100%", viewBox: "0 0 750 528" },
                    React.createElement(Path, { fill: colors.secondary, d: "M0,0V407S189.068,528,449,528c0,0,167.068,1.242,301-48V0H0Z" }))),
            React.createElement(View, { style: styles.contentContainer },
                React.createElement(LogoVertical, { width: logoAspectRatio * logoHeight, height: logoHeight, style: styles.logoImg }),
                React.createElement(Switch, null,
                    Platform.OS !== "web" && React.createElement(Route, { path: "/username", component: Username }),
                    Platform.OS !== "web" && React.createElement(Route, { path: "/signup", component: Signup }),
                    !utils.isRunningInTestLab && React.createElement(Route, { path: "/signupanon", component: SignupAnon }),
                    React.createElement(Route, { path: "/forgot-password", component: ForgotPassword }),
                    Platform.OS === "web" && React.createElement(Route, { path: "/password-reset", component: ResetPassword }),
                    Platform.OS === "web" && React.createElement(Route, { path: "/challenge/:username", component: Challenge }),
                    React.createElement(Route, { component: Platform.OS === "web" ? Info : LoginForm })),
                React.createElement(View, { style: loginStyles.textContainer },
                    React.createElement(Link, { to: "/about" },
                        React.createElement(AppText, { bold: true, style: [loginStyles.text, loginStyles.textLink] }, "Chizu AB")))))));
});
const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.primary,
        height: "100%",
    },
    contentContainer: {
        flex: 1,
        padding: Platform.OS === "web" ? pixelPerfect(20) : pixelPerfect(80),
        justifyContent: "space-between",
    },
    logo: {
        position: "absolute",
    },
    logoImg: {
        marginBottom: Platform.OS === "web" ? pixelPerfect(50) : pixelPerfect(213),
        alignSelf: "center",
    },
});
