import * as firebase from "firebase/app";
import { observable, decorate } from "mobx";
class AppState {
    constructor() {
        firebase.auth().onAuthStateChanged((user) => {
            this.user = user !== null && user !== void 0 ? user : undefined;
        });
    }
}
decorate(AppState, {
    user: observable,
});
export const appState = new AppState();
