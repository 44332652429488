import * as React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { observer } from "mobx-react";
import { pixelPerfect } from "../../helpers/pixelPerfect";
import { colors } from "../../styles/colors";
import { Header, P, Ul, Li } from "../../components/TextHelpers";
import { Header as PageHeader } from "../../components/Header";
import { Link } from "../../routing";
import { AppText } from "../../components/AppText";
export const About = observer(() => {
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, null),
        React.createElement(ScrollView, { style: styles.container },
            React.createElement(View, { style: styles.box },
                React.createElement(Header, { first: true }, "Om Chizu"),
                React.createElement(P, null, "Chizu drivs av Chizu AB, 559259-0706."),
                React.createElement(P, null, "Kontakta oss p\u00E5 hej@chizu.se"),
                React.createElement(Ul, null,
                    React.createElement(Li, null,
                        React.createElement(Link, { to: "/privacy" },
                            React.createElement(AppText, null, "Integritetspolicy"))),
                    React.createElement(Li, null,
                        React.createElement(Link, { to: "/terms" },
                            React.createElement(AppText, null, "Anv\u00E4ndarvillkor"))))))));
});
const styles = StyleSheet.create({
    container: {
        padding: pixelPerfect(20),
    },
    box: {
        padding: pixelPerfect(20),
        backgroundColor: colors.boxBackgroundColor,
        borderRadius: pixelPerfect(20),
    },
});
