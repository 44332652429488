import * as React from "react";
import { View, TouchableOpacity, Linking, StyleSheet } from "react-native";
import { pixelPerfect } from "../helpers/pixelPerfect";
import { colors } from "../styles/colors";
import { AppText } from "./AppText";
const styles = StyleSheet.create({
    container: {
        padding: pixelPerfect(20),
    },
    box: {
        padding: pixelPerfect(20),
        backgroundColor: colors.boxBackgroundColor,
        borderRadius: pixelPerfect(20),
    },
    header: {
        marginTop: pixelPerfect(30),
    },
    p: {
        marginTop: pixelPerfect(15),
    },
    ul: {
        marginTop: pixelPerfect(10),
        paddingLeft: pixelPerfect(40),
    },
    li: {
        flexDirection: "row",
        alignItems: "center",
    },
    liBullet: {
        width: 6,
        height: 6,
        borderRadius: 3,
        backgroundColor: "#000000",
        marginRight: pixelPerfect(10),
    },
    link: {},
});
export const Header = ({ children, first }) => {
    const firstStyle = first ? { marginTop: 0 } : undefined;
    return (React.createElement(AppText, { bold: true, style: [styles.header, firstStyle] }, children));
};
export const P = ({ children }) => {
    return React.createElement(AppText, { style: styles.p }, children);
};
export const Ul = ({ children }) => {
    return React.createElement(View, { style: styles.ul }, children);
};
export const Li = ({ children }) => {
    return (React.createElement(View, { style: styles.li },
        React.createElement(View, { style: styles.liBullet }),
        React.createElement(AppText, null, children)));
};
export const ExternalLink = ({ href, children }) => {
    const handleClick = () => {
        Linking.openURL(href);
    };
    return (React.createElement(TouchableOpacity, { onPress: handleClick, style: styles.link },
        React.createElement(AppText, null, children)));
};
