import React from "react";
import { observer } from "mobx-react";
import { View, StyleSheet, SafeAreaView, TouchableOpacity } from "react-native";
import { AppText } from "./AppText";
import { fonts } from "../styles/fonts";
import { colors } from "../styles/colors";
import { pixelPerfect } from "../helpers/pixelPerfect";
import { LogoText } from "../assets/LogoText";
import { IconButton } from "./IconButton";
import { useHistory } from "../routing";
import { ChevronLeft } from "../assets/icons/ChevronLeft";
export const Header = observer(() => {
    const history = useHistory();
    return (React.createElement(SafeAreaView, { style: styles.safeView },
        React.createElement(View, { style: styles.container },
            React.createElement(View, { style: styles.item },
                React.createElement(TouchableOpacity, { style: { flexDirection: "row", alignItems: "center" }, onPress: history.goBack },
                    React.createElement(IconButton, { small: true, style: { marginRight: pixelPerfect(10) } },
                        React.createElement(ChevronLeft, { color: "#fff" })),
                    React.createElement(AppText, { light: true, style: { fontSize: fonts.fontSmall } }, "Tillbaka"))),
            React.createElement(View, { style: [
                    styles.item,
                    {
                        justifyContent: "center",
                        alignItems: "center",
                    },
                ] },
                React.createElement(LogoText, { width: pixelPerfect(95), height: pixelPerfect(20) })),
            React.createElement(View, { style: styles.item }))));
});
const styles = StyleSheet.create({
    safeView: {
        backgroundColor: colors.primary,
    },
    container: {
        height: pixelPerfect(60),
        paddingHorizontal: pixelPerfect(10),
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    item: {
        flex: 1,
    },
});
