import React from "react";
import { View, StyleSheet } from "react-native";
import { AppText } from "../../components/AppText";
import { useParams } from "../../routing";
import { fonts } from "../../styles/fonts";
import { pixelPerfect } from "../../helpers/pixelPerfect";
export const Challenge = () => {
    const params = useParams();
    console.log("*** params", params);
    return (React.createElement(View, null,
        React.createElement(AppText, { bold: true, style: styles.header },
            params.username,
            " vill spela fr\u00E5gesportspelet Chizu mot dig"),
        React.createElement(AppText, { style: styles.body }, "Chizu \u00E4r ett spel som s\u00E4tter din allm\u00E4nbildning och dina geografikunskaper p\u00E5 prov"),
        React.createElement(AppText, { style: styles.body }, "\u00D6ppna l\u00E4nken du kom fr\u00E5n i din telefon (iPhone/Android) f\u00F6r att spela")));
};
const styles = StyleSheet.create({
    header: {
        fontSize: fonts.fontLarge,
        color: "#fff",
        textAlign: "center",
    },
    body: {
        color: "#fff",
        textAlign: "center",
        marginTop: pixelPerfect(20),
    },
});
