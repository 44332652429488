import React from "react";
import { StyleSheet, View } from "react-native";
import { Router, Switch, Route } from "./routing";
import { FireBaseContext, defaultFirebaseValue } from "./FirebaseContext";
import { colors } from "./styles/colors";
import { Login } from "./views/Login/Login";
import { Privacy } from "./views/Privacy/Privacy";
import { Terms } from "./views/Terms/Terms";
import { About } from "./views/About/About";
// @ts-ignore-start
const App = () => {
    return (React.createElement(FireBaseContext.Provider, { value: defaultFirebaseValue },
        React.createElement(View, { style: styles.container },
            React.createElement(Router, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/privacy" },
                        React.createElement(Privacy, null)),
                    React.createElement(Route, { path: "/terms" },
                        React.createElement(Terms, null)),
                    React.createElement(Route, { path: "/about" },
                        React.createElement(About, null)),
                    React.createElement(Route, null,
                        React.createElement(Login, null)))))));
};
const styles = StyleSheet.create({
    container: {
        height: "100vh",
        backgroundColor: colors.secondary,
        overflow: "hidden",
    },
});
export default App;
