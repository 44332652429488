import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { View, TextInput, StyleSheet, TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import { AppText } from "./AppText";
import { fonts } from "../styles/fonts";
import { colors } from "../styles/colors";
import { pixelPerfect } from "../helpers/pixelPerfect";
import { fontMaker } from "../helpers/fontMaker";
import { X } from "../assets/icons/X";
export const Input = observer(({ label, first, last, icon, clearFunc, ...props }) => {
    const inputRef = useRef(null);
    const inputEl = inputRef.current;
    const [focused, setFocused] = useState(false);
    const IconComponent = icon;
    const handleFocus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    return (React.createElement(TouchableWithoutFeedback, { onPress: handleFocus },
        React.createElement(View, { style: [styles.box, first ? styles.boxFirst : undefined, last ? styles.boxLast : undefined] },
            React.createElement(AppText, { style: [styles.label, props.value || focused ? styles.labelActive : undefined] }, label),
            IconComponent && (React.createElement(View, { style: styles.icon },
                React.createElement(IconComponent, null))),
            React.createElement(TextInput, Object.assign({ ref: inputRef, style: styles.input, onFocus: () => setFocused(true), onBlur: () => setFocused(false) }, props)),
            !!props.value && inputEl && clearFunc && (React.createElement(TouchableOpacity, { style: {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: pixelPerfect(87),
                    width: pixelPerfect(87),
                    justifyContent: "center",
                    alignItems: "center",
                }, onPress: () => clearFunc(inputEl) },
                React.createElement(X, null))))));
});
const styles = StyleSheet.create({
    box: {
        height: pixelPerfect(87),
        backgroundColor: colors.boxBackgroundColor,
        borderBottomColor: colors.grayLight,
        borderBottomWidth: 2,
        flexDirection: "row",
        marginTop: -1,
    },
    boxLast: {
        borderBottomRightRadius: pixelPerfect(20),
        borderBottomLeftRadius: pixelPerfect(20),
        borderBottomWidth: 0,
    },
    boxFirst: {
        borderTopRightRadius: pixelPerfect(20),
        borderTopLeftRadius: pixelPerfect(20),
    },
    input: {
        borderWidth: 0,
        fontSize: fonts.fontMedium,
        height: pixelPerfect(88),
        margin: pixelPerfect(20),
        marginTop: pixelPerfect(15),
        marginLeft: 0,
        flex: 1,
        ...fontMaker({ weight: "Bold" }),
    },
    label: {
        position: "absolute",
        top: pixelPerfect(27),
        left: pixelPerfect(112),
        fontSize: fonts.fontMedium,
        color: colors.grayDark,
        ...fontMaker({ weight: "Bold" }),
    },
    labelActive: {
        top: pixelPerfect(15),
        fontSize: fonts.fontSmaller,
    },
    icon: {
        backgroundColor: colors.grayLight,
        height: pixelPerfect(58),
        width: pixelPerfect(58),
        borderRadius: pixelPerfect(58 / 2),
        margin: pixelPerfect(15),
        marginLeft: pixelPerfect(20),
        marginRight: pixelPerfect(30),
        alignItems: "center",
        justifyContent: "center",
    },
});
