import React, { useState } from "react";
import { observer } from "mobx-react";
import { View, ActivityIndicator } from "react-native";
import { Input } from "../../components/Input";
import { Link, Redirect } from "../../routing";
import { AppButton, appButtonTextStyle } from "../../components/AppButton";
import { AppText } from "../../components/AppText";
import { appState } from "../../appState";
import { loginStyles } from "./login.styles";
import { Key } from "../../assets/icons/Key";
import { Mail } from "../../assets/icons/Mail";
import { auth } from "../../firebaseSetup";
import { FormError } from "../../components/FormError";
export const Signup = observer(() => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    if (appState.user) {
        return React.createElement(Redirect, { push: false, to: "/start" });
    }
    const handleCreateAccount = async () => {
        if (!email || !password) {
            return;
        }
        setLoading(true);
        return auth
            .createUserWithEmailAndPassword(email, password)
            .catch((error) => {
            switch (error.code) {
                case "auth/email-already-in-use":
                    setError("E-posten används redan");
                    break;
                case "auth/invalid-email":
                    setError("E-posten du angivet är inte en giltig e-post");
                    break;
                default:
                    setError("Vi kunde inte skapa ditt konto, prova igen senare eller kontakta oss på hej@chizu.se");
                    break;
            }
        })
            .then(() => {
            setLoading(false);
        });
    };
    return (React.createElement(View, null,
        React.createElement(AppText, { style: loginStyles.header, bold: true }, "SKAPA KONTO"),
        React.createElement(AppText, { style: loginStyles.headingParagraph }, "Fyll i din e-post och l\u00F6senord s\u00E5 k\u00F6r vi ig\u00E5ng!"),
        React.createElement(Input, { label: "E-post", icon: Mail, value: email, onChangeText: setEmail, textContentType: "emailAddress", keyboardType: "email-address", first: true }),
        React.createElement(Input, { label: "L\u00F6senord", icon: Key, value: password, onChangeText: setPassword, textContentType: "password", secureTextEntry: true, last: true }),
        React.createElement(FormError, { error: error }),
        React.createElement(View, { style: loginStyles.buttonContainer },
            React.createElement(AppButton, { style: loginStyles.btn, onPress: handleCreateAccount }, loading ? (React.createElement(ActivityIndicator, { size: "small", color: "#fff" })) : (React.createElement(AppText, { style: appButtonTextStyle }, "SKAPA KONTO")))),
        React.createElement(View, { style: loginStyles.textContainer },
            React.createElement(Link, { to: "/" },
                React.createElement(AppText, { style: [loginStyles.text, loginStyles.textLink] }, "Tillbaka")))));
});
