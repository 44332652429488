import React from "react";
import Svg, { Line, Circle } from "react-native-svg";
import { fonts } from "../../styles/fonts";
import { colors } from "../../styles/colors";
export const AlertCircle = ({ size = fonts.fontLarge, color = colors.secondary, style, ...rest }) => {
    return (React.createElement(Svg, Object.assign({ width: size, height: size, viewBox: "0 0 24 24", fill: "none", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", style: style }, rest),
        React.createElement(Circle, { cx: "12", cy: "12", r: "10" }),
        React.createElement(Line, { x1: "12", y1: "8", x2: "12", y2: "12" }),
        React.createElement(Line, { x1: "12", y1: "16", x2: "12.01", y2: "16" })));
};
