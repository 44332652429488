import React, { useState } from "react";
import { observer } from "mobx-react";
import { View, ActivityIndicator } from "react-native";
import { AppButton, appButtonTextStyle } from "../../components/AppButton";
import { AppText } from "../../components/AppText";
import { loginStyles } from "./login.styles";
import { fonts } from "../../styles/fonts";
import { useHistory, Link } from "../../routing";
import { crashlytics, auth } from "../../firebaseSetup";
export const SignupAnon = observer(() => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const handleAnonymously = async () => {
        crashlytics.log("Creating anonymous account");
        setLoading(true);
        await auth.signInAnonymously();
        history.replace("/start");
    };
    return (React.createElement(View, null,
        React.createElement(AppText, { style: loginStyles.header, bold: true }, "SKAPA ANONYMT KONTO"),
        React.createElement(AppText, { style: loginStyles.headingParagraph }, "Snabbast s\u00E4ttet att komma ig\u00E5ng, allt vi beh\u00F6ver \u00E4r ett anv\u00E4ndarnamn!"),
        React.createElement(AppText, { style: [loginStyles.headingParagraph, { fontSize: fonts.fontSmaller }] }, "OBS! Om du v\u00E4ljer att skapa ett konto utan e-post s\u00E5 kommer det inte g\u00E5 att \u00E5terst\u00E4lla din information om du t.ex. rensar appens data eller byter telefon."),
        React.createElement(View, { style: loginStyles.buttonContainer },
            React.createElement(AppButton, { style: loginStyles.btn, onPress: handleAnonymously }, loading ? (React.createElement(ActivityIndicator, { size: "small", color: "#fff" })) : (React.createElement(AppText, { style: appButtonTextStyle }, "SKAPA ANONYMT KONTO")))),
        React.createElement(View, { style: loginStyles.textContainer },
            React.createElement(Link, { to: "/signup" },
                React.createElement(AppText, { style: [loginStyles.text, loginStyles.textLink] }, "Skapa konto med e-post")),
            React.createElement(AppText, { style: loginStyles.text }, "eller"),
            React.createElement(Link, { to: "/" },
                React.createElement(AppText, { style: [loginStyles.text, loginStyles.textLink] }, "g\u00E5 tillbaka")))));
});
