import * as React from "react";
import { TouchableOpacity } from "react-native";
import { useHistory } from "react-router-dom";
export { useHistory, Redirect, Switch, Route, BrowserRouter as Router, useParams, useLocation, useRouteMatch, } from "react-router-dom";
export const BackButton = () => null;
export const Link = ({ component, children, to, onBlur, onFocus, ...otherProps }) => {
    const history = useHistory();
    const handleClick = React.useCallback(() => {
        if (typeof to === "string") {
            history.push(to);
        }
    }, []);
    return (React.createElement(TouchableOpacity, Object.assign({ onPress: handleClick }, otherProps), children));
};
