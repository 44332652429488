import React, { useState, useEffect } from "react";
import { View, ActivityIndicator } from "react-native";
import { observer } from "mobx-react";
export const FlexLoading = observer(({ light }) => {
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoader(true);
        }, 250);
        return () => clearTimeout(timer);
    }, []);
    if (!showLoader) {
        return null;
    }
    return (React.createElement(View, { style: { flex: 1, alignItems: "center", justifyContent: "center" } },
        React.createElement(ActivityIndicator, { size: "large", color: light ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)" })));
});
