import React from "react";
import { StyleSheet, SafeAreaView, ScrollView } from "react-native";
import { observer } from "mobx-react";
import { pixelPerfect } from "../../helpers/pixelPerfect";
import appStoreLogo from "./appstore.png";
export const Info = observer(() => {
    return (React.createElement(SafeAreaView, { style: { flex: 1 } },
        React.createElement(ScrollView, { contentContainerStyle: styles.contentContainer },
            React.createElement("a", { href: "https://play.google.com/store/apps/details?id=se.chizu.kartspelet" },
                React.createElement("img", { style: { maxWidth: "100%" }, alt: "Ladda ned p\u00E5 Google Play", src: "https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png" })),
            React.createElement("a", { href: "https://apps.apple.com/se/app/chizu/id1525241138" },
                React.createElement("img", { style: { maxWidth: "100%" }, alt: "Ladda ner fr\u00E5n App Store", src: appStoreLogo })))));
});
const styles = StyleSheet.create({
    container: {},
    contentContainer: {
        flex: 1,
        padding: pixelPerfect(80),
        alignItems: "center",
    },
    logo: {
        position: "absolute",
    },
    logoImg: {
        marginBottom: pixelPerfect(213),
        alignSelf: "center",
    },
});
