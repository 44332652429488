import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { View, ActivityIndicator } from "react-native";
import { Input } from "../../components/Input";
import { Redirect } from "../../routing";
import { AppButton, appButtonTextStyle } from "../../components/AppButton";
import { AppText } from "../../components/AppText";
import { appState } from "../../appState";
import { loginStyles } from "./login.styles";
import { FireBaseContext } from "../../FirebaseContext";
import { Key } from "../../assets/icons/Key";
import { useLocation } from "react-router-dom";
import { FlexLoading } from "../../components/Loading";
export const ResetPassword = observer(() => {
    const { auth } = useContext(FireBaseContext);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false);
    const location = useLocation();
    const code = new URLSearchParams(location.search).get("oobCode");
    const [error, setError] = useState(code ? "" : "Länken du klicka på är inte fullständig");
    useEffect(() => {
        if (code) {
            auth.verifyPasswordResetCode(code)
                .then((res) => {
                setCodeVerified(true);
            })
                .catch((e) => {
                switch (e.code) {
                    case "auth/invalid-action-code":
                        setError("Länken du klicka på är inte längre giltig eller inte fulltständig");
                        break;
                    case "auth/expired-action-code":
                        setError("Länken du klicka på är för gammal, skicka efter en ny");
                        break;
                    default:
                        setError("Nu blev något tokigt, försök igen senare!");
                }
            });
        }
    }, []);
    if (appState.user) {
        return React.createElement(Redirect, { push: false, to: "/start" });
    }
    const handlePasswordReset = async () => {
        if (!password || !code) {
            return;
        }
        setLoading(true);
        await auth.confirmPasswordReset(code, password);
        setLoading(false);
        setPasswordReset(true);
        setPassword("");
    };
    if (error) {
        return (React.createElement(View, { style: { alignItems: "center" } },
            React.createElement(AppText, { style: { color: "#fff" } }, error)));
    }
    if (!codeVerified) {
        return React.createElement(FlexLoading, null);
    }
    return (React.createElement(View, null,
        React.createElement(AppText, { style: loginStyles.header, bold: true }, "\u00C5TERST\u00C4LL L\u00D6SENORD"),
        React.createElement(AppText, { style: loginStyles.headingParagraph }, "Ange ett nytt l\u00F6senord och logga sen in i appen igen"),
        React.createElement(Input, { label: "L\u00F6senord", icon: Key, value: password, onChangeText: setPassword, textContentType: "password", secureTextEntry: true, first: true, last: true }),
        React.createElement(View, { style: loginStyles.buttonContainer },
            React.createElement(AppButton, { disabled: loading || passwordReset, style: loginStyles.btn, onPress: handlePasswordReset }, loading ? (React.createElement(ActivityIndicator, { size: "small", color: "#fff" })) : (React.createElement(AppText, { style: appButtonTextStyle }, passwordReset ? "NYTT LÖSENORD SATT" : "ÅTERSTÄLL LÖSENORD"))))));
});
