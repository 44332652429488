import React, { useState } from "react";
import { observer } from "mobx-react";
import { View, ActivityIndicator } from "react-native";
import { Input } from "../../components/Input";
import { Redirect, Link } from "../../routing";
import { AppButton, appButtonTextStyle } from "../../components/AppButton";
import { AppText } from "../../components/AppText";
import { appState } from "../../appState";
import { loginStyles } from "./login.styles";
import { Key } from "../../assets/icons/Key";
import { Mail } from "../../assets/icons/Mail";
import { fonts } from "../../styles/fonts";
import { colors } from "../../styles/colors";
import { pixelPerfect } from "../../helpers/pixelPerfect";
import { auth } from "../../firebaseSetup";
import { FormError } from "../../components/FormError";
export const LoginForm = observer(() => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    if (appState.user) {
        return React.createElement(Redirect, { push: false, to: "/start" });
    }
    const handleLogin = async () => {
        if (!email || !password || loading) {
            return;
        }
        setLoading(true);
        return auth.signInWithEmailAndPassword(email, password).catch((error) => {
            setLoading(false);
            if (error.code === "auth/invalid-email") {
                setError("E-posten du angivet är inte en giltig e-post");
                return;
            }
            setError("Vi kunde inte logga in med det e-posten och lösenordet du angett");
        });
    };
    return (React.createElement(View, null,
        React.createElement(Input, { label: "E-post", icon: Mail, value: email, onChangeText: setEmail, textContentType: "emailAddress", keyboardType: "email-address", first: true }),
        React.createElement(View, null,
            React.createElement(Input, { label: "L\u00F6senord", icon: Key, value: password, onChangeText: setPassword, textContentType: "password", secureTextEntry: true, last: true }),
            React.createElement(Link, { style: {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: pixelPerfect(82),
                    justifyContent: "center",
                    paddingRight: pixelPerfect(30),
                }, to: "/forgot-password" },
                React.createElement(AppText, { style: { fontSize: fonts.fontSmall, color: colors.grayDark } }, "Gl\u00F6mt l\u00F6senord?"))),
        React.createElement(FormError, { error: error }),
        React.createElement(View, { style: loginStyles.buttonContainer },
            React.createElement(AppButton, { style: loginStyles.btn, onPress: handleLogin }, loading ? (React.createElement(ActivityIndicator, { size: "small", color: "#fff" })) : (React.createElement(AppText, { style: appButtonTextStyle }, "LOGGA IN")))),
        React.createElement(View, { style: loginStyles.textContainer },
            React.createElement(Link, { to: "/signup" },
                React.createElement(AppText, { style: [loginStyles.text, loginStyles.textLink] }, "Skapa konto")),
            React.createElement(AppText, { style: loginStyles.text }, "eller"),
            React.createElement(Link, { to: "/signupanon" },
                React.createElement(AppText, { style: [loginStyles.text, loginStyles.textLink] }, "forts\u00E4tt utan e-post")))));
});
