import { Platform } from "react-native";
const font = {
    OpenSans: {
        weights: {
            Bold: "Bold",
            Normal: "Normal",
        },
        styles: {
            Italic: "italic",
        },
    },
};
// generate styles for a font with given weight and style
export const fontMaker = (options = {}) => {
    let { weight, style, family } = Object.assign({
        weight: "",
        style: "",
        family: "OpenSans",
    }, options);
    const { weights, styles } = font[family];
    if (Platform.OS === "android") {
        const fontWeight = weight && weights[weight] ? weight : "";
        const fontStyle = style && styles[style] ? style : "";
        const suffix = fontWeight + fontStyle;
        return {
            fontFamily: family + (suffix.length ? `-${suffix}` : ""),
        };
    }
    else if (Platform.OS === "web") {
        const fontWeight = (weight && weights[weight]) || weights.Normal;
        const fontStyle = (style && styles[style]) || "normal";
        return {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: fontWeight.toLowerCase(),
            fontStyle,
        };
    }
    else {
        const fontWeight = (weight && weights[weight]) || weights.Normal;
        const fontStyle = (style && styles[style]) || "normal";
        return {
            fontFamily: "OpenSans-Regular",
            fontWeight: fontWeight.toLowerCase(),
            fontStyle,
        };
    }
};
