// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyDpM1HPBaJoiKI_c8yI1O_8z22ObfXO9ow",
    authDomain: "kartspelet.firebaseapp.com",
    databaseURL: "https://kartspelet.firebaseio.com",
    projectId: "kartspelet",
    storageBucket: "kartspelet.appspot.com",
    messagingSenderId: "944558320168",
    appId: "1:944558320168:web:48b16e10edbf0d3f37e31d",
    measurementId: "G-H2EGQSZPG3",
};
export default firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const functions = firebase.app().functions("europe-west1");
export const auth = firebase.auth();
export const fieldPathDocumentId = firebase.firestore.FieldPath.documentId();
export const analytics = firebase.analytics();
export const utils = {
    isRunningInTestLab: false,
};
export const messaging = firebase.messaging();
export const crashlytics = {
    log: (message) => { },
    recordError: (error) => { },
    setUserId: (userId) => { },
};
export const dynamicLinks = {
    getInitialLink: async () => {
        return { url: "" };
    },
    onLink: () => {
        return () => {
            /* NOPE */
        };
    },
    buildLink: (options) => { },
};
