import React from "react";
import { observer } from "mobx-react";
import { View, StyleSheet } from "react-native";
import { pixelPerfect } from "../helpers/pixelPerfect";
import { AlertCircle } from "../assets/icons/AlertCircle";
import { AppText } from "./AppText";
export const FormError = observer(({ error }) => {
    if (!error) {
        return null;
    }
    return (React.createElement(View, { style: styles.container },
        React.createElement(AlertCircle, { color: "#fff", style: styles.icon }),
        React.createElement(AppText, { style: styles.text }, error)));
});
const styles = StyleSheet.create({
    container: {
        marginVertical: pixelPerfect(10),
        padding: pixelPerfect(20),
        borderRadius: pixelPerfect(20),
        backgroundColor: "#db3252",
        paddingLeft: pixelPerfect(20),
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginRight: pixelPerfect(20),
    },
    text: {
        color: "#fff",
        flex: 1,
        flexWrap: "wrap",
    },
});
