import { StyleSheet } from "react-native";
import { pixelPerfect } from "../../helpers/pixelPerfect";
import { fonts } from "../../styles/fonts";
export const loginStyles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: pixelPerfect(12),
    },
    btn: {
        flex: 1,
    },
    textContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: pixelPerfect(40),
    },
    text: {
        color: "#fff",
        marginHorizontal: pixelPerfect(5),
    },
    textLink: {
        borderBottomWidth: 1,
        borderBottomColor: "#fff",
    },
    headingParagraph: {
        color: "#fff",
        marginHorizontal: pixelPerfect(5),
        textAlign: "center",
        marginBottom: pixelPerfect(40),
    },
    header: {
        color: "#fff",
        fontSize: fonts.fontLarge,
        textAlign: "center",
        marginBottom: pixelPerfect(20),
    },
});
