import React from "react";
import { observer } from "mobx-react";
import { Text, StyleSheet, TouchableOpacity } from "react-native";
import { colors } from "../styles/colors";
import { pixelPerfect } from "../helpers/pixelPerfect";
import { fonts } from "../styles/fonts";
import { fontMaker } from "../helpers/fontMaker";
export const AppButton = observer(({ onPress, title, children, small, style, disabled }) => {
    return (React.createElement(TouchableOpacity, { disabled: disabled, style: [styles.container, style, { opacity: disabled ? 0.5 : 1 }], onPress: onPress },
        title && React.createElement(Text, { style: [styles.text, small ? styles.textSmall : undefined] }, title),
        children));
});
export const appButtonTextStyle = {
    fontSize: fonts.fontMedium,
    color: "#fff",
    ...fontMaker({ weight: "Bold" }),
};
const styles = StyleSheet.create({
    container: {
        borderRadius: pixelPerfect(20),
        height: pixelPerfect(80),
        backgroundColor: colors.secondary,
        alignItems: "center",
        justifyContent: "center",
    },
    text: appButtonTextStyle,
    textSmall: {
        fontSize: fonts.fontSmall,
    },
});
