import React from "react";
import { observer } from "mobx-react";
import { View, StyleSheet } from "react-native";
import { pixelPerfect } from "../helpers/pixelPerfect";
import { colors } from "../styles/colors";
export const IconButton = observer(({ small, primary, light, style, children }) => {
    return (React.createElement(View, { style: [
            styles.container,
            primary ? styles.primary : undefined,
            small ? styles.small : undefined,
            light ? styles.light : undefined,
            style,
        ] }, children));
});
const styles = StyleSheet.create({
    container: {
        height: pixelPerfect(65),
        width: pixelPerfect(65),
        backgroundColor: "rgba(255,255,255,0.15)",
        borderRadius: pixelPerfect(20),
        justifyContent: "center",
        alignItems: "center",
    },
    small: {
        height: pixelPerfect(40),
        width: pixelPerfect(40),
        borderRadius: pixelPerfect(10),
    },
    primary: {
        opacity: 1,
        backgroundColor: colors.primary,
    },
    light: {
        backgroundColor: colors.grayLight,
        opacity: 1,
    },
});
