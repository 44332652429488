import React, { useState, useContext, useCallback } from "react";
import { observer } from "mobx-react";
import { View, ActivityIndicator } from "react-native";
import { Input } from "../../components/Input";
import { useHistory } from "../../routing";
import { AppButton, appButtonTextStyle } from "../../components/AppButton";
import { AppText } from "../../components/AppText";
import { FireBaseContext } from "../../FirebaseContext";
import { appState } from "../../appState";
import { loginStyles } from "./login.styles";
import { User } from "../../assets/icons/User";
import { FormError } from "../../components/FormError";
export const Username = observer(() => {
    var _a;
    const { db } = useContext(FireBaseContext);
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const userId = (_a = appState.user) === null || _a === void 0 ? void 0 : _a.uid;
    if (!userId) {
        return null;
    }
    const handleSetUserName = async () => {
        if (username.length > 16) {
            setError("Användarnamnet får inte vara längre än 16 tecken");
            return;
        }
        if (username.length < 3) {
            setError("Användarnamnet måste vara längre än 3 tecken");
            return;
        }
        if (!/^[a-zåäö.1-9]+$/gi.test(username)) {
            setError("Användarnamnet får bara innehålla bokstäverna a-ö, siffror och punkt");
            return;
        }
        setLoading(true);
        const foundUser = await db.collection("users").where("username", "==", username).get();
        if (foundUser.size) {
            setError("Användarnamnet är redan upptaget!");
            return;
        }
        await db.collection("users").doc(userId).update({
            username: username.toLowerCase(),
        });
        history.replace("/start");
    };
    const handleChangeText = useCallback((val) => {
        setUsername(val.replace(/\s/gi, ""));
    }, []);
    return (React.createElement(View, null,
        React.createElement(AppText, { style: loginStyles.header, bold: true }, "SNART KLAR"),
        React.createElement(AppText, { style: loginStyles.headingParagraph }, "Innan vi s\u00E4tter ig\u00E5ng beh\u00F6ver du s\u00E4tta ett anv\u00E4ndarnamn som v\u00E4nner och andra kan hitta dig genom"),
        React.createElement(Input, { autoCapitalize: "none", label: "Anv\u00E4ndarnamn", value: username, onChangeText: handleChangeText, last: true, first: true, icon: User, onBlur: () => setUsername(username.toLowerCase()) }),
        React.createElement(FormError, { error: error }),
        React.createElement(View, { style: loginStyles.buttonContainer },
            React.createElement(AppButton, { style: loginStyles.btn, onPress: handleSetUserName }, loading ? (React.createElement(ActivityIndicator, { size: "small", color: "#fff" })) : (React.createElement(AppText, { style: appButtonTextStyle }, "SPARA"))))));
});
