export const colors = {
    primary: "#cf2243",
    primaryDimmed: "#d84a65",
    secondary: "#17263c",
    boxBackgroundColor: "#ffffff",
    boxTextColor: "#080808",
    boxTextDimmedColor: "#949494",
    grayDark: "#949494",
    grayLight: "#d8d8d8",
    green: "#3bc021",
};
