import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import { View, ActivityIndicator } from "react-native";
import { Input } from "../../components/Input";
import { Link, Redirect } from "../../routing";
import { AppButton, appButtonTextStyle } from "../../components/AppButton";
import { AppText } from "../../components/AppText";
import { appState } from "../../appState";
import { loginStyles } from "./login.styles";
import { Mail } from "../../assets/icons/Mail";
import { FireBaseContext } from "../../FirebaseContext";
import { FormError } from "../../components/FormError";
export const ForgotPassword = observer(() => {
    const { auth } = useContext(FireBaseContext);
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    if (appState.user) {
        return React.createElement(Redirect, { push: false, to: "/start" });
    }
    const handlePasswordReset = async () => {
        if (!email) {
            return;
        }
        setLoading(true);
        var actionCodeSettings = {
            url: "https://chizu.se/?email=" + email,
        };
        auth.sendPasswordResetEmail(email, actionCodeSettings)
            .then(() => {
            setLoading(false);
            setEmailSent(true);
            setEmail("");
            setError("");
        })
            .catch((e) => {
            switch (e.code) {
                case "auth/invalid-email":
                    setError("Du har angett en ogiltig e-post");
                    break;
                case "auth/user-not-found":
                    setError("Vi kunde inte hitta en användare med den e-posten");
                    break;
                default:
                    setError("Något gick fel, prova igen");
            }
            setLoading(false);
        });
    };
    return (React.createElement(View, null,
        React.createElement(AppText, { style: loginStyles.header, bold: true }, "GL\u00D6MT L\u00D6SENORD"),
        React.createElement(AppText, { style: loginStyles.headingParagraph }, "Fyll i din e-post s\u00E5 skickar vi ett mail f\u00F6r att \u00E5terst\u00E4lla ditt l\u00F6senord"),
        React.createElement(Input, { label: "E-post", icon: Mail, value: email, onChangeText: setEmail, textContentType: "emailAddress", keyboardType: "email-address", first: true, last: true }),
        React.createElement(FormError, { error: error }),
        React.createElement(View, { style: loginStyles.buttonContainer },
            React.createElement(AppButton, { disabled: loading || emailSent, style: loginStyles.btn, onPress: handlePasswordReset }, loading ? (React.createElement(ActivityIndicator, { size: "small", color: "#fff" })) : (React.createElement(AppText, { style: appButtonTextStyle }, emailSent ? "ÅTERSTÄLLNINGSMAIL SKICKAT" : "SKICKA")))),
        React.createElement(View, { style: loginStyles.textContainer },
            React.createElement(Link, { to: "/" },
                React.createElement(AppText, { style: [loginStyles.text, loginStyles.textLink] }, "Tillbaka")))));
});
